import React from 'react';
import MemberPhoto from './MemberPhoto/MemberPhoto';
import './MembersGallery.scss'

function MembersGallery() {
    return (<div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='vivid'/>
            <MemberPhoto name='amai'/>
            <MemberPhoto name='wero'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='gumis'/>
            <MemberPhoto name='grazka'/>
            <MemberPhoto name='natsu'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='gloomy'/>
            <MemberPhoto name='xell'/>
            <MemberPhoto name='missmorrow'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='yume'/>
            <MemberPhoto name='potterka'/>
            <MemberPhoto name='kartofelek'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='gin'/>
            <MemberPhoto name='natsumi'/>
            <MemberPhoto name='freivena'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='tsukki'/>
            <MemberPhoto name='aypirate'/>
            <MemberPhoto name='yooya'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='joe'/>
            <MemberPhoto name='ven'/>
            <MemberPhoto name='ransy'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='zen'/>
            <MemberPhoto name='toru'/>
            <MemberPhoto name='shiki'/>
        </div>
        <div className='membersGalleryRow'>
            <MemberPhoto name='owcunia'/>
            <MemberPhoto name='pika'/>
        </div>
    </div>)
}

export default MembersGallery;