export enum LeftEmojis { Ayato = "Ayato", Kaeya = "Kaeya" };
export enum RightEmojis { Tartaglia = "Tartaglia", Thoma = "Thoma" };

export enum QuestProgress { inProgress = "inProgress", completed = "completed", failed = "failed" };
export enum Region { liyue = "Liyue", monstadt = "Monstadt", inazuma = "Inazuma", sumeru = "Sumeru", fontaine = "Fontaine"};

export enum Language {
    PL = "PL",
    EN = "EN",
}

export enum Location {
    mobile = "mobile",
    stand = "stand"
}

export enum menuPLTitles { Daily = "Daily Quests", About = "O nas", Support = "Wsparcie", Calendar = "Kalendarz", Instagram = "Instagram"}
export enum menuENTitles { Daily = "Daily Quests", About = "About us", Support = "Donations", Calendar = "Calendar", Instagram = "Instagram"}

export interface QuestDescription {
    namePL: string,
    nameEN: string,
    region: Region,
}

export interface StandQuestDescription extends QuestDescription {
    descriptionPL: string,
    descriptionEN: string,
}

export type Member = {
    name: string;
    socialMediaUrl: string;
    imgUrl: string,
}

export const Members: Map<string, Member> = new Map([
    ["amai", {name: "Amai", socialMediaUrl: "https://www.instagram.com/amaicosplay", imgUrl: '../../members/amai.jpg'}],
    ["fuki", {name: "Fuki", socialMediaUrl: "https://www.instagram.com/fukari_stuff/", imgUrl: '../../members/fuki.jpg'}],
    ["gin", {name: "Gin", socialMediaUrl: "https://www.instagram.com/gin.wtf/", imgUrl: '../../members/gin.jpg'}],
    ["gloomy", {name: "Gloomy", socialMediaUrl: "https://www.instagram.com/gloomysunday.cosp", imgUrl: '../../members/gloomy.jpg'}],
    ["grazka", {name: "Grażka", socialMediaUrl: "https://www.instagram.com/grazka.cosp", imgUrl: '../../members/grazka.jpg'}],
    ["gumis", {name: "Gumiś", socialMediaUrl: "https://www.instagram.com/gumis0422/", imgUrl: '../../members/gumis.jpg'}],
    ["kaho", {name: "Kaho", socialMediaUrl: "https://www.instagram.com/kahosia/", imgUrl: '../../members/kaho.jpg'}],
    ["karo", {name: "Karo", socialMediaUrl: "https://www.instagram.com/karo.crevan/", imgUrl: '../../members/karo.jpg'}],
    ["kartofelek", {name: "KartoFelek", socialMediaUrl: "https://www.instagram.com/karto._.felek/", imgUrl: '../../members/kartofelek.jpg'}],
    ["lin", {name: "Linisscze", socialMediaUrl: "https://www.instagram.com/linisscze/", imgUrl: '../../members/lin.jpg'}],
    ["melody", {name: "Melody", socialMediaUrl: "https://www.instagram.com/flamemelody", imgUrl: '../../members/melody.jpg'}],
    ["missmorrow", {name: "Miss Morrow", socialMediaUrl: "https://www.instagram.com/miss.morow/", imgUrl: '../../members/missmorrow.jpg'}],
    ["natsu", {name: "Natsu", socialMediaUrl: "https://www.instagram.com/natsu_coss/", imgUrl: '../../members/natsu.jpg'}],
    ["nazar", {name: "Nazar", socialMediaUrl: "https://www.instagram.com/nazarcosplay/", imgUrl: '../../members/nazar.jpg'}],
    ["potterka", {name: "Potterka", socialMediaUrl: "https://www.instagram.com/kinga.potter/", imgUrl: '../../members/potterka.jpg'}],
    ["tonayo", {name: "Tonayo", socialMediaUrl: "https://www.instagram.com/tonayo_chan/", imgUrl: '../../members/tonayo.jpg'}],
    ["vivid", {name: "Vivid", socialMediaUrl: "https://www.instagram.com/vividcosplay/", imgUrl: '../../members/vivid.jpg'}],
    ["wero", {name: "Wero", socialMediaUrl: "https://www.instagram.com/werolelo/", imgUrl: '../../members/wero.jpg'}],
    ["xell", {name: "Xell", socialMediaUrl: "https://www.instagram.com/pinkroselatte/", imgUrl: '../../members/xell.jpg'}],
    ["yoshi", {name: "Yoshi", socialMediaUrl: "https://www.instagram.com/yoshinightmares/", imgUrl: '../../members/yoshi.jpg'}],
    ["yume", {name: "Yume", socialMediaUrl: "https://www.instagram.com/yume.kun/", imgUrl: '../../members/yume.jpg'}],
    ["natsumi", {name: "Natsumi", socialMediaUrl: "https://www.instagram.com/natsumi.shika/", imgUrl: '../../members/natsumi.jpg'}],
    ["freivena", {name: "Freivena", socialMediaUrl: "https://www.instagram.com/freivena/", imgUrl: '../../members/freivena.jpg'}],
    ["tsukki", {name: "Tsukki", socialMediaUrl: "https://www.instagram.com/t_tsukki_cosplay/", imgUrl: '../../members/tsukki.jpg'}],
    ["aypirate", {name: "Aypirate", socialMediaUrl: "https://www.instagram.com/aypirate/", imgUrl: '../../members/aypirate.jpg'}],
    ["yooya", {name: "Yooya", socialMediaUrl: "https://www.instagram.com/yooyka_/", imgUrl: '../../members/yooya.jpg'}],
    ["joe", {name: "Joe", socialMediaUrl: "https://www.instagram.com/joecoser/", imgUrl: '../../members/joe.jpg'}],
    ["ven", {name: "Ven", socialMediaUrl: "https://www.instagram.com/ven_xi_/", imgUrl: '../../members/ven.jpg'}],
    ["ransy", {name: "Ransy", socialMediaUrl: "https://www.instagram.com/ransy_cos/", imgUrl: '../../members/ransy.jpg'}],
    ["zen", {name: "Zen", socialMediaUrl: "https://www.instagram.com/zen.shenn/", imgUrl: '../../members/zen.jpg'}],
    ["toru", {name: "Toru", socialMediaUrl: "https://www.instagram.com/blueberrytxru/", imgUrl: '../../members/toru.jpg'}],
    ["shiki", {name: "Shiki", socialMediaUrl: "https://www.instagram.com/shiki.cosp/", imgUrl: '../../members/shiki.jpg'}],
    ["owcunia", {name: "Owcunia", socialMediaUrl: "https://www.instagram.com/owcunia_cosp/", imgUrl: '../../members/owcunia.jpg'}],
    ["pika", {name: "Pika", socialMediaUrl: "https://www.instagram.com/_pika.kiwi_/", imgUrl: '../../members/pika.jpg'}],
])

// TRANSLATIONS

export enum menuPLDescriptions { 
    Daily = "dołącz do gildii poszukiwaczy przygód w grze konwentowej", 
    About = "dowiedz się więcej o projekcie", 
    Support = "dopomóż naszej inicjatywie", 
    Instagram = "zobacz nasze social media",
    Calendar = "sprawdź na jakich konwentach pojawimy się w tym roku"
};

export enum menuENDescriptions { 
    Daily = "join the Adventure Guild in our Convention Game", 
    About = "learn more about the project", 
    Support = "support our initiative", 
    Instagram = "check out our social media",
    Calendar = "check on which conventions can you find us"
};

export const menuWelcomeTextPL = "Witaj Podróżniku!\n Wejdź śmiało w świat Genshina razem z nami! Przygotowaliśmy dla Ciebie dużo atrakcji..."
export const menuWelcomeTextEN = "Welcome Traveler! Come and join Genshin World with us! We've prepared many attractions for you..."

export const selectLanguageTextPL = "Wybierz język aplikacji:"
export const selectLanguageTextEN = "Select application language:"

export const languageChangeAlertTextPL = "Zmieniono język aplikacji na Polski"
export const languageChangeAlertTextEN = "Application language changed to English"

export const calendarTextPL = "W tym roku możecie odwiedzić Project:Genshin na następujących wydarzeniach:"
export const calendarTextEN = "In this year you can find Project:Genshin during the following events:"

export const aboutUs1PL = "Jesteśmy fanowską inicjatywą, której celem jest przeniesienie elementów z uniwersum Genshin Impact do naszego świata!"
export const aboutUs2PL = "Który z fanów Genshina nie uwielbia zanurzać się w świecie Teyvatu? Piękne postacie, łapiące za serce historie i niezwykłe widoki… To wszystko ujęło nas tak bardzo, że zdecydowaliśmy się, że chcemy więcej!"
export const aboutUs3PL = "Nasza grupa składa się aktualnie z 22 osób. Zajmujemy się cosplayem, rękodzielnictwem, tworzeniem sztuki... Łączy nas miłość do świata Teyvatu, którą chcemy Wam przybliżyć w naszej oddolnej inicjatywie jaką jest Project Genshin!"
export const aboutUs4PL = "Pojawiamy się na różnych eventach, prezentując przygotowane przez nas scenografie, cosplaye, gry terenowe, warsztaty artystyczne, konkursy i prelekcje. W trakcie trwania Hikari i Animeconu byliśmy zachwyceni, widząc jak rozglądacie się za electroculusami, geoculusami i wyszukujecie obecności Abyss Magów."
export const aboutUs5PL = "Wraz z Waszym wsparciem możemy sprawić że nasze działania będą jeszcze lepsze i będziemy mogli pojawić się na większej ilości wydarzeń!"
export const aboutUs6PL = "Pianka, farby, tkaniny... Wszystkie rzeczy z których własnoręcznie tworzymy nasze dekoracje mają swoją cenę. Wpłaty pomogą nam opłacać koszty materiałów i dzięki temu zaskoczymy Was większą ilością wspaniałych genshinowych craftów."
export const aboutUs7PL = "Widząc Wasz zachwyt czujemy, że przenosimy kawałek świata Genshin Impact z ekranów do naszej rzeczywistości!"
export const aboutUs8PL = "Chcemy przenieść go jeszcze więcej, a będzie to możliwe dzięki Waszej pomocy!"
export const whereCanYouFindUsPL = "Gdzie możesz nas znaleźć?"

export const aboutUs1EN = "We are a fan-driven initiative to bring elements from the Genshin Impact universe to our real world!"
export const aboutUs2EN = "Which Genshin fan doesn't love to dive into the world of Teyvat? Beautiful characters, captivating stories and amazing views… All this captivated us so much that we decided that we wanted more!"
export const aboutUs3EN = "Our group currently consists of 22 people. We create cosplays, crafts, art and  even more... We share a love for the world of Teyvat, which we want to bring to you in our initiative, which is Project Genshin!"
export const aboutUs4EN = "We visit various events, presenting our scenography, cosplays, outdoor games, art workshops, competitions and lectures. Throughout Hikari and Animecon, we were thrilled to see you looking for electroculus, geoculus, and Abyss Mage presence."
export const aboutUs5EN = "Together with your support, we can make our activities even better and we will be able to appear at more events!"
export const aboutUs6EN = "Foam, paints, fabrics... All the things we use to create our decorations ourselves have their price. Contributions will help us pay for the cost of materials and thanks to this we will surprise you with more great genshin crafts."
export const aboutUs7EN = "Seeing your admiration, we feel that we are transferring a piece of the Genshin Impact world from the screens to our reality!"
export const aboutUs8EN = "We want to create even more, and it will be possible with your help!"
export const whereCanYouFindUsEN = "Where can you find us?"

export const rulesTighnariPart1WhatArePL = "Czym są "
export const rulesTighnariPart1DailyQuestsPL = "Daily Questy"
export const rulesTighnariPart1PL = "To gra konwentowa, do której możesz przystąpić raz dziennie. Masz szansę wykazać się w Gildii Poszukiwaczy Przygód i na jej zlecenie podjąć się 4 drobnych zadań..."
export const rulesTighnariPart1ReadMorePL = "Kliknij, aby czytać dalej"

export const rulesTighnariPart2PL = "Po poprawnym wykonaniu 4 misji, odwiedź stoisko Gildii. Katherine ma przygotowaną nagrodę dla każdego, kto podołał zadaniom."
export const rulesTighnariPart2GoodLuckPL = "Powodzenia, Podróżniku!"
export const rulesTighnariPart2ReadAgainPL = "Kliknij, aby przeczytać jeszcze raz"

export const rulesTighnariPart1WhatAreEN = "What are "
export const rulesTighnariPart1DailyQuestsEN = "Daily Quests"
export const rulesTighnariPart1EN = "It's a conplace game you can play once per day. You have an opportunity to show up your abilities in Adventure Guild and take commisions for 4 small tasks..."
export const rulesTighnariPart1ReadMoreEN = "Click to read more"

export const rulesTighnariPart2EN = "After succesfuly completing 4 missions visit our Adventure Guild on Conplace. Katherine prepared a reward for everyone who managed to finish the quests."
export const rulesTighnariPart2GoodLuckEN = "Good luck, Traveler!"
export const rulesTighnariPart2ReadAgainEN = "Click to read once again"

export const rulesHeaderTextPL = "Zasady przystąpienia do zabawy:"
export const rulesHeaderTextEN = "Rules of the game:"

export const rules1PL = "Nie używaj przeglądarki w trybie incognito (prywatnym). Tylko wtedy jesteśmy w stanie notować postępy w Twoich zadaniach!"
export const rules2PL = "W razie problemów z aplikacją zgłoś się do stanowiska Gildii Poszukiwaczy Przygód (Adventure Guild)"
export const rules3PL = "Jeśli któraś z misji nie będzie Ci odpowiadać bądź nie uda Ci się jej wykonać, możesz dwa razy wylosować nowe zadanie"
export const rules4PL = "Nagrodę można odebrać raz dziennie po poprawnym wykonaniu 4 misji."
export const rules5PL = "W ramach gry można łączyć zadania z aplikacji z misjami rozdawanymi na stanowisku Gildii Poszukiwaczy Przygód."
export const rules6PL = "Jeśli nie uda Ci się poprawnie wykonać 4 wymaganych questów, głowa do góry – spróbuj swoich sił kolejnego dnia! Codziennie Katherine przygotowuje nowe zadania."

export const rules1EN = "Don't use your browser in incognito (private) mode. Only this way we can monitor the progress of your quests!"
export const rules2EN = "In case of any problems please contact our Adventure Guild on the conplace."
export const rules3EN = "If you won't be able to complete a mission, you can draw a new mission twice."
export const rules4EN = "You can collect the reward only once per day after completing 4 quests."
export const rules5EN = "During the game you can combine quests that are carried out in the application and on the conplace in the Adventure Guild."
export const rules6EN = "If you won't succesfuly complete 4 required quests, don't worry - try your best the next day! Every day Katherine prepares new quests for the adventurers."

export const rulesStartGamePL = "Zacznij grę"
export const rulesStartGameEN = "Start game"

export const introductionHeaderPL = "Na początek powiedz nam jak się nazywasz, Podróżniku"
export const introductionHeaderEN = "First tell us your name, Traveler"

export const introductionInputPL = "wprowadź swoje imię"
export const introductionInputEN = "enter your name"

export const introductionButtonPL = "Wylosuj zadania"
export const introductionButtonEN = "Draw quests"

export const questListAyato1PL = "Witaj, "
export const questListAyato2PL = "Poniżej widnieją questy, które Kathrine wybrała dla Ciebie na dzisiaj. "
export const questListAyato3PL = "Kliknij w nie, aby wyświetlić treść zadania."

export const questListAyato1EN = "Welcome, "
export const questListAyato2EN = "Below are the quests Kathrine has chosen for you today. "
export const questListAyato3EN = "Click on them to view the content of the task."

export const questListAyakaPL = "Powodzenia! W razie problemów zapraszamy na nasze stoisko! Po wykonaniu 4 zadań zgłoś się na stoisko Adventure Guild w wiosce Genshina"
export const questListAyakaEN = "Good luck! If you have any problems, please visit our stand! After completing 4 quests come to the Adventure Guild in Genshin Village"

export const mobilePL = "Aplikacja"
export const mobileEN = "Application"
export const standPL = "Teren konwentu"
export const standEN = "Conplace"

export const questGoBackToMenuPL = "Powrót do menu"
export const questGoBackToMenuEN = "Go back to menu"

export const modalYesPL = "Tak"
export const modalYesEN = "Yes"

export const modalNoPL = "Nie"
export const modalNoEN = "No"

export const completeQuestPL = "Kliknij, aby zaliczyć questa"
export const completeQuestEN = "Click to complete the quest"

export const enterPasswordPL = "Wprowadź hasło"
export const enterPasswordEN = "Enter password"

export const checkPasswordPL = "Sprawdź hasło"
export const checkPasswordEN = "Check password"

export const qrCodeNotWorkingPL = "Jeśli nie jesteś w stanie zeskanować kodu QR, poproś na stoisku Adventure Guild o wprowadzenie hasła"
export const qrCodeNotWorkingEN = "If you cannot scan the QR code, please ask a person at the Adventure Guild Booth to enter a password"

export const giveUpPL = "Poddaj się"
export const giveUpEN = "Give up"

export const scanQrCodePL = "Zeskanuj kod QR"
export const scanQrCodeEN = "Scan QR code"

export const reedemReward1PL = "Udało Ci się zaliczyć "
export const reedemReward2PL = " z 4 questów. Podejdź do stoiska Adventure Guild i zeskanuj odpowiedni kod"

export const reedemReward1EN = "You completed  "
export const reedemReward2EN = " out of 4 quests. Go to the Adventure Guild booth and scan given QR code."

export const questListScreenFailedPL = "Niestety nie udało Ci się wykonać więcej niż 2 powierzonych Ci questów - głowa do góry i spróbuj swoich sił jutro!"
export const questListScreenFailedEN = "Unfortunately, you failed to complete more than 2 of the quests entrusted to you - keep your head up and try your luck tomorrow!"

export const questListScreenPartiallyFailedPL = "Udało Ci się wykonać 3 na 4 zadania, podejdź na stoisko Adventure Guild i kliknij w ten dymek, aby wylosować jedno nowe zadanie"
export const questListScreenPartiallyFailedEN = "You completed 3 out of 4 quests, go to the Adventure Guild booth and click on the bubble to draw one new quest"

export const questListScreenCompletedPL = "Gratulacje! Udało Ci się zaliczyć wszystkie 4 questy, kliknij w ten dymek i podejdź do stoiska Adventure Guild odebrać nagrodę!"
export const questListScreenCompletedEN = "Congratulations! You managed to complete all 4 quests, click on this speech bubble and go to the Adventure Guild booth to collect your reward!"

export const questListScreenCompletedAndRedeemedPL = "Odebrałeś już nagrodę za wykonane zadania - zapraszamy do gry następnego dnia!"
export const questListScreenCompletedAndRedeemedEN = "You have already redeemed your reward for completed quests - we invite you to play the next day!"

// TEST POSZUKIWACZA PRZYGOD

export const testPoszukiwaczaSubmitButtonTextPL ="Zatwierdź odpowiedzi"
export const testPoszukiwaczaSubmitButtonTextEN ="Submit answers"

export const testPoszukiwaczaInstructionsBubbleTextPL = "Zlecenie dla Gildii zostawiła nawet Paimon, która jest bardzo ciekawa, czy czasami jej słuchamy! By zaliczyć to zadanie, odpowiedz na poprawnie na minimum trzy z czterech pytań."
export const testPoszukiwaczaInstructionsBubbleTextEN = "Even Paimon, who is very curious if we listen to her sometimes, left a comission for the Guild! To complete this quest, answer at least three out of four questions correctly."

export const testPoszukiwaczaInstructionsStartTestPL = "Zacznij test"
export const testPoszukiwaczaInstructionsStartTestEN = "Begin test"

export const testPoszukiwaczaFailedPL = "Niestety, odpowiedziałxś źle na pytania. Powróć do menu questów, aby sprawdzić postęp swojej gry lub wyświetl swoje odpowiedzi"
export const testPoszukiwaczaFailedEN = "Unfortunately you answered incorrectly. Go back to quest menu to check the progress of your game or view your answers"

export const testPoszukiwaczaCompletedPL = "Gratulacje! Odpowiedziałxś poprawnie na pytania! Powróć do menu questów, aby sprawdzić postęp swojej gry lub wyświetl swoje odpowiedzi"
export const testPoszukiwaczaCompletedEN = "Congratulations! You answered correctly to the answers! Go back to quest menu to check the progress of your game or view your answers"

export const testPoszukiwaczaViewAnswersPL = "Wyświetl odpowiedzi"
export const testPoszukiwaczaViewAnswersEN = "View answers"



// WYMIANA JEZYKOWA

export const wymianaJezykowaEmojiTextPL = "Dostaliśmy zlecenie od Elli Musk z Mondstadt, znanej badaczki języka hilichurli. Bardzo zależy jej na dobrych stosunkach pomiędzy ludźmi a badaną przez nią rasą. By wypełnić misję, podejdź wraz z Ellą do najbliższego obozu hilichurli i przywitaj się z nimi po hilichurlańsku!"
export const wymianaJezykowaEmojiTextEN = "We got a commission from Ella Musk from Mondstadt, a well-known researcher of the hilichurli language. She cares a lot about good relations between people and the race she studies. To complete the mission, go with Ella to the nearest hilichurl camp and say hello in Hilichurlan!"

export const wymianaJezykowaInstructionPL = "Przywitaj się po hilichurlańsku:"
export const wymianaJezykowaInstructionEN = "Say 'hello' in hilichurl's language:"

export const wymianaJezykowaInputPlaceholderPL = "Wpisz odpowiednie słowo"
export const wymianaJezykowaInputPlaceholderEN = "Enter a correct word"

export const wymianaJezykowaSubmitButtonTextPL = "Zatwierdź odpowiedź"
export const wymianaJezykowaSubmitButtonTextEN = "Submit answer"

export const wymianaJezykowaCompletedTextPL = "Gratulacje! Udało Ci się udzielić poprawnej odpowiedzi!"
export const wymianaJezykowaCompletedTextEN = "Congratulations! You have given the correct answer!"

export const wymianaJezykowaFailedHilichurlText = "Ya yika! Nini zido!"

export const wymianaJezykowaFailedEllaTextPL = "Niestety, nie udało Ci się poprawnie przywitać z hilichurlem. Ale nie martw się, to trudny język. Następnym razem spróbuj powitać hilichurla słowem: Olah!"
export const wymianaJezykowaFailedEllaTextEN = "Sorry, you failed to greet hilichurl correctly. But don't worry, it's a difficult language. Next time, try greeting hilichurl with word: Olah!"



// JAN KEN PON

export const questJanKenPonStartGamePL = "Zacznij grę"
export const questJanKenPonStartGameEN = "Start game"

export const questJanKenPonInstructions1PL = "Wielki Yokai Mujina znów prześladuje dzieci w Inazumie! Yoimiya prosi wszystkich chętnych o przygotowanie się do bitwy ze złośliwym stworem, psującym zabawki i ukrywającym skarpetki. Sposobem walki z niecnym stworzeniem będzie turniej jan ken pon! Zagraj z Paimon do trzech wygranych, by przygotować się na ostateczną potyczkę!"
export const questJanKenPonInstructions1EN = "The great Yokai Mujina is stalking the children of Inazuma again! Yoimiya asks all comers to prepare for a battle with a mischievous creature that breaks toys and hides socks. The way to fight the evil creature will be the jan ken pon tournament! Play to three wins with Paimon to prepare for the final showdown!"

export const questJanKenPonInstructions2PL = "Ciekawostka: “jan ken pon” to japoński odpowiednik gry w “papier-kamień-nożyce”!"
export const questJanKenPonInstructions2EN = "Fun fact: “jan ken pon” is the Japanese equivalent of the game of “rock-paper-scissors”!"

export const questJanKenPonScorePL = "WYNIK"
export const questJanKenPonScoreEN = "SCORE"

export const questJanKenPonChooseSymbolTextPL = "Wybierz symbol"
export const questJanKenPonChooseSymbolTextEN = "Choose symbol"

export const questJanKenPonChosenSymbolTextPL = "Wybrany symbol"
export const questJanKenPonChosenSymbolTextEN = "Chosen symbol"

export const questJanKenPonNextRoundPL = "Następna runda"
export const questJanKenPonNextRoundEN = "Next round"

export const questJanKenPonNextPL = "Dalej"
export const questJanKenPonNextEN = "Next"

export const questJanKenPonCompletedTextPL = "Gratulacje! Wygrałeś z Paimon w Jan Ken Pon!"
export const questJanKenPonCompletedTextEN = "Congratulations! You won with Paimon in Jan Ken Pon!"

export const questJanKenPonFailedTextPL = "Niestety przegrałeś z Paimon w Jan Ken Pon, powodzenia następnym razem!"
export const questJanKenPonFailedTextEN = "Unfortunately you lost with Paimon in Jan Ken Pon. Good luck next time!"


// SZKOLENIE GRZYBIARZA

export const questSzkolenieGrzybiarzaInfoTextPL = "Wystarczy już poszukiwaczy przygód, którym muszę co chwilę podawać odtrutkę po zatruciu się grzybami. Jeśli chcesz zwiedzać las, musisz być w stanie wskazać, który z tych grzybów jest jadalny:"
export const questSzkolenieGrzybiarzaInfoTextEN = "Enough adventurers who I have to give mushroom poisoning antidote every now and then. If you want to explore the forest, you must be able to tell which of these mushrooms are edible:"

export const questSzkolenieGrzybiarzaCompletedPL = "Poprawna odpowiedź! Świetnie sobie radzisz w rozpoznawaniu grzybów. Możesz spokojnie ruszać na te swoje przygody!"
export const questSzkolenieGrzybiarzaCompletedEN = "Correct answer! You're great at identifying mushrooms. You can go on your adventures with peace of mind!"

export const questSzkolenieGrzybiarzaFailedPL = "Ugh, to nie jest właściwa odpowiedź - jadalny grzyb został oznaczony w zielonej ramce! Uważaj na przyszłość!"
export const questSzkolenieGrzybiarzaFailedEN = "Ugh, that's not the right answer - the edible mushroom has been marked in a green box! Watch out for the future!"

export const questSzkolenieGrzybiarzaConfirmPL = "Zatwierdź odpowiedź"
export const questSzkolenieGrzybiarzaConfirmEN = "Confirm answer"



// PERLY SANGONOMIYI

export const perlySangonomiyiInfo1PL = "Jubiler z Liyue przybył do Inazumy, by pozyskać nowe materiały do swoich pięknych wyrobów. Zdobądź dla niego perły z Watatsumi! Przygotowałam dla Ciebie mapę z zaznaczoną lokacją pereł."
export const perlySangonomiyiInfo1EN = "A jeweler from Liyue came to Inazuma to obtain new materials for his beautiful wares. Get pearls from Watatsumi for him! I have prepared a map for you with the location of the pearls marked."

export const perlySangonomiyiInfo2PL = "Odnajdź perły na stoisku H2 w okolicach Project Genshin i zeskanuj kod QR lub wpisz hasło tekstowe!"
export const perlySangonomiyiInfo2EN = "Find the pearls at stand H2 near Project Genshin and scan the QR code or enter the text password!"

export const perlySangonomiyiBeginScanningPL = "Rozpocznij skanowanie"
export const perlySangonomiyiBeginScanningEN = "Begin scanning"

export const perlySangonomiyiGoBackToMapPL = "Powrót do mapy"
export const perlySangonomiyiGoBackToMapEN = "Back to map"

export const perlySangonomiyiGiveUpButtonPL = "Poddaj się z poszukiwaniami"
export const perlySangonomiyiGiveUpButtonEN = "Give up your search"

export const perlySangonomiyiGiveUpModalPL = "Czy jesteś pewien, że chesz zrezygnować? Poddanie się oznacza niezaliczenie zadania."
export const perlySangonomiyiGiveUpModalEN = "Are you sure you want to quit? Giving up means failing the task."

export const perlySangonomiyiCompletedPL = "Udało Ci się odnaleźć perły, gratulacje!"
export const perlySangonomiyiCompletedEN = "You found the pearls, congratulations!"

export const perlySangonomiyiFailedPL = "Mimo starań nie udało Ci się odnaleźć pereł. Powodzenia następnym razem, Podróżniku!"
export const perlySangonomiyiFailedEN = "Despite your best efforts, you failed to find the pearls. Good luck next time, Traveler!"



// MISTRZ I UCZEN

export const questMistrziUczenInfo1PL = "Ach, miło Cię widzieć! Pamiętasz moje warsztaty z pisania poezji podczas Windblume? Cóż, przydałoby mi się trochę mory na... ekhem... jabłka! Dlatego przygotowuję nowe lekcje! Pomożesz mi sprawdzić skuteczność moich technik nauczania?"
export const questMistrziUczenInfo1EN = "Ah, nice to see you! Remember my poetry writing workshop at Windblume? Well, I could use some mora on... ahem... apples! That's why I'm preparing new lessons! Will you help me test the effectiveness of my teaching techniques?"

export const questMistrziUczenInfo2PL = "Zaprezentuję Ci wiersz, a Ty spróbuj uzupełnić brakujące wersy, wybierając jedną z opcji! Pamiętaj tylko, choć bard ma prawo nieco koloryzować... nie powinien kłamać!"
export const questMistrziUczenInfo2EN = "I will present you a poem and your task is to complete the missing lines by choosing one of the options! Just remember, while the bard has the right to exaggerate a bit... he shouldn't lie!"

export const questMistrziUczenBeginPL = "Zacznij uzupełnianie"
export const questMistrziUczenBeginEN = "Start filling"

export const questMistrzFillGapPL = "uzupełnij wiersz wybierając pasującą opcję"
export const questMistrzFillGapEN = "complete the row by selecting the matching option"

export const questMistrzSubmitAnswerPL = "Zatwierdź odpowiedź"
export const questMistrzSubmitAnswerEN = "Submit answer"

export const questMistrzFailedPart1PL = 'Niestety nie jest to prawidłowa odpowiedź. Prawidłowa odpowiedź, to:'
export const questMistrzFailedPart1EN = 'Unfortunately, this is not the correct answer. The correct answer is:'
export const questMistrzFailedPart2PL = 'Spróbuj swoich sił następnym razem, Podróżniku!'
export const questMistrzFailedPart2EN = 'Try your luck next time, Traveler!'

export const questMistrzCompletedPL = "Gratulacje! To prawidłowa odpowiedź! Dobra robota, bardzie!"
export const questMistrzCompletedEN = "Congratulations! This is the correct answer! Good job, bard!"



// poem1

export const poem1correctAnswer = 2;

export const poem1verse1PL = "Mknąc po niebie"
export const poem1verse2PL = "poprzez przestworza"
export const poem1verse3PL = "Ryczy w gniewie"
export const poem1verse4PL = "przeklęty przez Abyss"

export const poem1verse1EN = "Streaking through the blazing sky"
export const poem1verse2EN = "among the mighty expanses"
export const poem1verse3EN = "Roaring and thumbling, driven by wrath"
export const poem1verse4EN = "cursed by the endless Abbys"

export const poem1answer1part1PL = "Niegdyś walczył dzielnie"
export const poem1answer1part2PL = "chroniąc swe Liyue"
export const poem1answer1part1EN = "Once fought courageously and thus"
export const poem1answer1part2EN = "Protecting Liyue, like a hero he was"

export const poem1answer2part1PL = "D’Valin niegdyś kochał ziemię"
export const poem1answer2part2PL = "Mondstadt jego domem"
export const poem1answer2part1EN = "D'Valin once adored the city beyond"
export const poem1answer2part2EN = "Mondstadt - his pride, his love, his home"

export const poem1answer3part1PL = "Niegdyś bronił knieję"
export const poem1answer3part2PL = "ten feniks złocisty"
export const poem1answer3part1EN = "Once risked life for woodland"
export const poem1answer3part2EN = "yonder flaming, burning phoenix"

// poem2

export const poem2correctAnswer = 3;

export const poem2verse1PL = "Walcząc ze smokiem"
export const poem2verse2PL = "zdobyła swą chwałę"
export const poem2verse3PL = "Okryta wiecznym honorem"
export const poem2verse4PL = "przekroczyła progi Celestii"

export const poem2verse1EN = "Facing the enormous dragon"
export const poem2verse2EN = "thereby earning crown glory"
export const poem2verse3EN = "Covered with endless awe"
export const poem2verse4EN = "She crossed the Celestial gate"

export const poem2answer1part1PL = "Zwana w Mond bogiem"
export const poem2answer1part2PL = "czczona w katedrze"
export const poem2answer1part1EN = "In Mond they named her in favor"
export const poem2answer1part2EN = "thus served in cathedral; 'our God, our Savior'"

export const poem2answer2part1PL = "Pierwszym została lordem"
export const poem2answer2part2PL = "oddała się w pełni miastu"
export const poem2answer2part1EN = "The first Lord back then; she became"
export const poem2answer2part2EN = "and served the city with righteous hand"

export const poem2answer3part1PL = "Ochroniła Mond przed mrokiem"
export const poem2answer3part2PL = "pełna brawury Vannessa"
export const poem2answer3part1EN = "She defended Mond from devouring eter"
export const poem2answer3part2EN = "the glorious and daring Vanessa"


// poem3

export const poem3correctAnswer = 2;

export const poem3verse1PL = "W złotych promieniach blasku"
export const poem3verse2PL = "wpływa do portu statek"
export const poem3verse3PL = "Do miasta, w którym już od samego brzasku"
export const poem3verse4PL = "handel rozkwita bujnie"
export const poem3verse5PL = "i woń się niesie wspaniała."
export const poem3verse6PL = "Tu pyszne chili, tam wachlarz"
export const poem3verse7PL = "i kryształ szkli się dostojnie"

export const poem3verse1EN = "In golden rays of dazzling sun"
export const poem3verse2EN = "the argosy arrives at harbor"
export const poem3verse3EN = "A Land, when the merchants awaken"
export const poem3verse4EN = "as the sun raises above the horizon"
export const poem3verse5EN = "The scent of ground chili"
export const poem3verse6EN = "The breeze from dancing fans"
export const poem3verse7EN = "and ground glass; reflection of stars"

export const poem3answer1part1PL = "Wita cię Inazuma -"
export const poem3answer1part2PL = "pustynia i zaduma"
export const poem3answer1part1EN = "Inazuma welcomes you, Traveller"
export const poem3answer1part2EN = "don't stray too far, the land knows no farewell"

export const poem3answer2part1PL = "To Liyue właśnie - "
export const poem3answer2part2PL = "tutaj góry i baśnie"
export const poem3answer2part1EN = "Come my child, Liyue longed for you"
export const poem3answer2part2EN = "the mountains of triumph shall now lead through"

export const poem3answer3part1PL = "Zawsze jesteś u siebie -"
export const poem3answer3part2PL = "w Mond pomogą w potrzebie"
export const poem3answer3part1EN = "Do you feel the wind, whispering around?"
export const poem3answer3part2EN = "Will you come near Mond to explore this sound?"



// KU CZCI PIECIU KASEN

export const questKuCzciInfo1PL = "W Inazumie odbywa się Irodori Festival ku czci pięciu Kasen, wielkich poetów. W ramach tego święta organizatorzy zachęcają do tworzenia własnej poezji! Napisz haiku o Inazumie."
export const questKuCzciInfo1EN = "In Inazuma, the Irodori Festival is held in honor of the five Kasen, great poets. As part of this holiday, the organizers encourage you to create your own poetry! Write a haiku about Inazuma."

export const questKuCzciInfo2PL = "Haiku jest to japoński wiersz składający się z trzech wersów. Poszczególne wersy muszą mieć następującą liczbę sylab: pierwszy – 5, drugi – 7, trzeci – 5."
export const questKuCzciInfo2EN = "Haiku is a Japanese poem consisting of three lines. Individual lines must have the following number of syllables: first - 5, second - 7, third - 5."

export const questKuCzciBeginWritingPL = "Rozpocznij pisanie"
export const questKuCzciBeginWritingEN = "Begin writing"

export const questKuCzciPreviewButtonPL = "Kliknij, aby zobaczyć przykład"
export const questKuCzciPreviewButtonEN = "Click to see an example"

export const questKuCzciEditorInfo1PL = "Wprowadź poniżej tekst swojego wiersza. Pamiętaj, żeby zdbać o odpowiednią liczbę sylab w każdym wersie:"
export const questKuCzciEditorInfo1EN = "Please enter your poem text below. Remember to use the correct number of syllables in each line:"

export const questKuCzciEditorInfo2PL = "pierwszy – 5, drugi – 7, trzeci – 5."
export const questKuCzciEditorInfo2EN = "first - 5, second - 7, third - 5."

export const questKuCzciEditorSubmitPL = "Zatwierdź"
export const questKuCzciEditorSubmitEN = "Submit"

export const questKuCzciEditorEnterHaikuPlaceholderPL = "Wprowadź swój wiersz"
export const questKuCzciEditorEnterHaikuPlaceholderEN = "Enter your poem"

export const questKuCzciEditorModalInfoPL = "Przykład haiku:"
export const questKuCzciEditorModalInfoEN = "Example of haiku:"

export const questKuCzciEditorModalExamplePL = "po upalnym dniu \njakież wytchnienie niesie \nnoc pełna rosy"
export const questKuCzciEditorModalExampleEN = "An old silent pond\nA frog jumps into the pond\nSplash! Silence again."

export const questKuCzciPreviewInfoPL = "Twoje haiku wraz z liczbą sylab:"
export const questKuCzciPreviewInfoEN = "Your haiku with the number of syllables:"

export const questKuCzciPreviewCompletedPL = "Gratulacje! Niezły z Ciebie poeta - napisałeś poprawne haiku."
export const questKuCzciPreviewCompletedEN = "Congratulations! You're quite a poet - you've written a good haiku."

export const questKuCzciPreviewWrongPL = "Niestety nie zgadza się liczba sylab w poszczególnych wersach - popraw swój wiersz, aby liczba sylab w poszczególnych wersach wynosiła: pierwszy – 5, drugi – 7, trzeci – 5. "
export const questKuCzciPreviewWrongEN = "Unfortunately, the number of syllables in each line is not included - correct your poem so that the number of syllables in each line is: first - 5, second - 7, third - 5."

export const questKuCzciPreviewGoBackToEditingPL = "Powrót do edycji"
export const questKuCzciPreviewGoBackToEditingEN = "Go back to editing"

export const questKuCzciPreviewGiveUpButtonPL = "Poddaj się"
export const questKuCzciPreviewGiveUpButtonEN = "Give up"

export const questKuCzciPreviewGiveUpTextPL = "Czy jesteś pewien, że chesz zrezygnować? Poddanie się oznacza niezaliczenie zadania."
export const questKuCzciPreviewGiveUpTextEN = "Are you sure you want to quit? Giving up means failing the quest."

export const questKuCzciFailedPL = "Mimo starań nie udało Ci się napisać poprawnego haiku. Powodzenia następnym razem, Podróżniku!"
export const questKuCzciFailedEN = "Despite your efforts, you failed to write a correct haiku. Good luck next time, Traveler!"



// QUEST WRITTEN IN THE STARS

export const questWrittenInTheStarsInfoPL = "Na wydziale astronomii Rtawahist w Akademyi prowadzone są badania nad gwiazdami. Otrzymałam niezwykle ważne zadanie, aby powiązać konstelacje astrologiczne z ich przedstawicielami, w czym musisz mi pomóc. Odgadnij minimum 2 z 3 konstelacji, aby zaliczyć questa!"
export const questWrittenInTheStarsInfoEN = "The Rtawahist Astronomy Department at the Academy conducts research about the stars. I have been given an extremely important task to associate the astrological constellations with their representatives, which you kave to help me with. Guess at least 2 of the 3 constellations to complete the quest!"

export const questWrittenInTheStarsStartQuestPL = "Zacznij ogadywanie"
export const questWrittenInTheStarsStartQuestEN = "Start guessing"

export const questWrittenInTheStarsGivenConstellationPL = "Pokazana powyżej konstelacja należy do:"
export const questWrittenInTheStarsGivenConstellationEN = "The constellation shown above belongs to:"

export const questWrittenInTheStarsConfirmPL = "Zatwierdź odpowiedź"
export const questWrittenInTheStarsConfirmEN = "Confirm answer"

export const questWrittenInTheStarsCorrectAnswersPL = "Poprawne odpowiedzi to:"
export const questWrittenInTheStarsCorrectAnswersEN = "Correct answers are:"

export const questWrittenInTheStarsFailedPL = "Niestety nie udało Ci się poprawnie dopasować konstelacji. Musisz doszkolić się w dziedzinie astrologii, dlatego poniżej przygotowałam dla Ciebie poprawne odpowiedzi. Powodzenia następnym razem!"
export const questWrittenInTheStarsFailedEN = "Sorry, you failed to match the constellations correctly. You need to train yourself in the field of astrology, which is why I have prepared the correct answers for you below. Good luck next time!"

export const questWrittenInTheStarsCompletedPL = "Gratulacje! Udało Ci się poprawnie dopasować co najmniej 2 postacie do ich konstelacji. Jesteś prawdziwym mistrzem astrologii! Poniżej przygotowałam dla Ciebie małe podsumowanie."
export const questWrittenInTheStarsCompletedEN = "Congratulations! You successfully matched at least 2 characters to their constellations. You are a true master of astrology! I have prepared a small summary for you below."


// QUEST ABYSS ORDER

export const questAbyssOrderDescriptionPL = "Ostatnio niepokojąco wzrosła aktywność Abyss Order. Znajdź na terenie konwentu przynajmniej trzech różnych Abyss Mage (z różnymi żywiołami) i zeskanuj kod QR przy nich umieszczony (lub wprowadź hasło tekstowe). Kliknij w maga, aby wyświetlić podpowiedź, gdzie możesz go odnaleźć."

export const questAbyssOrderDescriptionEN = "Abyss Order activity has increased alarmingly recently. Find at least three different Abyss Mages (with different elements) in the convention area and scan the QR code on them (or enter a text password). Click on each mage to display a hint where to find it."

export const questAbyssOrderFindMagePL = "Znajdź maga"
export const questAbyssOrderFindMageEN = "Find mage"

export const questAbyssOrderHintPL = "Wskazówka: "
export const questAbyssOrderHintEN = "Hint: "

export const questAbyssOrderCompletedPL = "Gratulacje! Udało Ci się odnaleźć wrogów. Dzięki Tobie mieszkańcy Teyvatu mogą spać spokojnie."
export const questAbyssOrderCompletedEN = "Congratulations! You have found enemies. Thanks to you, the people of Teyvat can sleep peacefully."



// QUEST LOST PACKAGE

export const questLostPackageDescriptionPL = "Ktoś zostawił do doręczenia przesyłkę dla przyjaciela, ale niestety zapomniałam zapisać sobie imię odbiorcy. Pamiętam jednak kilka ciekawostek, które usłyszałam o tej osobie. Poszukiwaczu przygód, czy potrafisz mi pomóc i podać imię osoby po usłyszeniu jej opisu?"
export const questLostPackageDescriptionEN = "Someone left a package for a friend to deliver, but unfortunately I forgot to write down the recipient's name. However, I remember a few interesting facts that I heard about this person. Adventurer, can you help me and tell me the name of the person after hearing their description?"

export const questLostPackagePackageBelongsToPL = "Paczka zaadresowana jest do:"
export const questLostPackagePackageBelongsToEN = "The package is addressed to:"

export const questLostPackageSubmitAnswerPL = "Zatwierdź odpowiedź"
export const questLostPackageSubmitAnswerEN = "Submit answer"

export const questLostPackageCompletedPL = "Gratulacje! To poprawna odpowiedź - może powinieneś w przyszłości dołączyć do Komaniya Expres!"
export const questLostPackageCompletedEN = "Congratulations! That's the correct answer - maybe you should join Komaniya Express in the future!"

export const questLostPackageFailedPL = "Oj, niestety to błędna odpowiedź! Miejmy nadzieję, że paczka trafi we właściwe ręce..."
export const questLostPackageFailedEN = "Oh, sorry, that's the wrong answer! Let's hope the package ends up in the right hands..."


// QUEST KWIATY DOWOD MILOSCI

export const questKwiatyDowodMilosciCompletedPL = "Gratulacje! Udało Ci się poprawnie dopasować co najmniej 2 bukiety kwiatów. Poniżej przygotowałam dla Ciebie małe podsumowanie."
export const questKwiatyDowodMilosciCompletedEN = "Congratulations! You successfully matched at least 2 flower bouquets. I have prepared a small summary for you below."

export const questKwiatyDowodMilosciFailedPL = "Niestety nie udało Ci się poprawnie dopasować bukietów. Florystyka bywa ciężką sztuką, dlatego poniżej przygotowałam dla Ciebie poprawne odpowiedzi. Powodzenia następnym razem!"
export const questKwiatyDowodMilosciFailedEN = "Unfortunately, you failed to match the bouquets correctly. Floristry can be a difficult art, so I have prepared the correct answers summary for you below. Good luck next time!"

export const questKwiatyDowodMilosciInfoPL = "Teyvat przemierza wiele wspaniałych bohaterów, lecz nawet bohaterowi będzie miło, gdy otrzyma kwiaty. Pomóż dopasować bukiet kwiatów do osoby, która ma go otrzymać. Dobieraj biorąc pod uwagę, które kwiaty wymagane są do podnoszenia levelu danej postaci."
export const questKwiatyDowodMilosciInfoEN = "Many great heroes roam Teyvat, but even a hero would be happy to receive flowers. Match a bouquet of flowers to the person who will receive it. Take into account which flowers are required to increase the level of a given character when choosing!"

export const questKwiatyDowodMilosciInfoButtonPL = "Zaczynajmy!"
export const questKwiatyDowodMilosciInfoButtonEN = "Let's begin!"

export const questKwiatyDowodMilosciGivenFlowersPL = "Ten bukiet kwiatów powinien trafić do:"
export const questKwiatyDowodMilosciGivenFlowersEN = "This flower bouqet should be given to:"